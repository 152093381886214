var expandInfoPositions = {
  SIDE: 'SIDE',
  BOTTOM: 'BOTTOM'
};
var imageRatioType = {
  FIXED: 'FIXED',
  RESPONSIVE: 'RESPONSIVE'
};
var dimensions = {
  SIDE_BAR_WIDTH: 430,
  STORE_SIDE_BAR_WIDTH: 560,
  MOBILE_PADDING: 120,
  NO_PADDING: 0
};
var mobileSwipeAnimations = {
  EXPAND: 'EXPAND',
  FADE: 'FADE',
  CAROUSEL: 'CAROUSEL',
  CROSSFADE: 'CROSSFADE'
};
var expandAnimations = {
  NO_EFFECT: 'NO_EFFECT',
  EXPAND: 'EXPAND',
  FADE_IN: 'FADE_IN',
  ZOOM: 'ZOOM'
};
var slideTransitions = {
  LINEAR: 'linear',
  EASE_IN: 'cubic-bezier(0.86,0,0.5,1)',
  EASE_OUT: 'cubic-bezier(0.31,1.17,0.58,1.07)',
  EASE_IN_OUT: 'cubic-bezier(0.46,0.1,0.25,1)',
  BOUNCE_IN: 'cubic-bezier(0.49,-0.12,0.49,0.04)',
  BOUNCE_OUT: 'cubic-bezier(0.58,1.65,0.29,0.95)',
  BOUNCE_IN_OUT: 'cubic-bezier(0.6,-0.36,0.41,1.23)',
  EXPO: 'cubic-bezier(0.86,0,0.07,1)'
};
var dataSavedState = {
  SAVED: 'SAVED',
  PUBLISHED: 'PUBLISHED'
};
var autoSlideshowTypes = {
  INTERVAL: 'interval',
  CONTINUOUS: 'continuous'
};
export var SENTRY_DSN = 'https://53ff996300e94e7983242fc53bc63c5f@sentry.io/1360936';
export default {
  imageRatioType: imageRatioType,
  dimensions: dimensions,
  mobileSwipeAnimations: mobileSwipeAnimations,
  expandAnimations: expandAnimations,
  expandInfoPositions: expandInfoPositions,
  dataSavedState: dataSavedState,
  slideTransitions: slideTransitions,
  autoSlideshowTypes: autoSlideshowTypes
};